import { Button } from '@mui/material'
import React from 'react'

function RunningText() {
// loadRunniug
  return (
    <div>
        <Button>Add</Button>

    </div>
  )
}

export default RunningText